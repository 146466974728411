import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmPrompt from '../General/ConfirmPrompt';
import './Brands.scss';

import BrandsNewRegistrationCode from './BrandsNewRegistrationCode';
import TagSidebar from '../Tags/TagSidebar';
import Tooltip from '../General/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

import {
  addRegistrationCode,
  deleteRegistrationCode,
  updateRegistrationCode,
  updateBrand,
  updateBrandSettings,
  sendBudgetInvoice as sendBudgetInvoiceAPI
} from '../../api/brands';
import { getBrands, getBrandRegistrationCodes, deleteBrandTag } from '../../actions/brandActions';
import { toggleBrandBeingTagged, setBrandsBeingTagged } from '../../actions/uiActions';

import { isTaggingMode, isTagTypeHidden, isBrandBeingTagged, getActiveTags } from '../../helpers/ui_helpers';
import { isSubscribedToFeature } from '../../helpers/subscription_helpers';
import { objectMatchesActiveTags } from '../../helpers/tag_helpers';
import { getPrettyNumber } from '../../helpers/formatting';
import { getRootSMSUrl, copyToClipboard } from '../../helpers/helpers';
import { isValidCountryCode } from '../../helpers/geo_helpers';

const Brands = props => {
  const { getBrands, getBrandRegistrationCodes, brands, ui } = props;
  const { all, codes } = brands || {};
  const [addingNewCode, setAddingNewCode] = useState(false);
  const [themingBrand, setThemingBrand] = useState(false);
  const [selectedAccountManagerId, setSelectedAccountManagerId] = useState(null);
  const [sortType, setSortType] = useState(['id']);
  const [sortOrder, setSortOrder] = useState(['desc']);
  const [showingAllRegistrationCodes, setShowingAllRegistrationCodes] = useState(false);
  const [brandSearchVal, setBrandSearchVal] = useState('');
  const allBrands = all || [];

  const inTaggingMode = isTaggingMode(ui);

  const clickSortType = type => {
    setSortType([type, 'id']);
    const alreadySelected = sortType[0] === type;
    alreadySelected ? setSortOrder([sortOrder[0] === 'desc' ? 'asc' : 'desc', 'desc']) : setSortOrder(['desc', 'desc']);
  };

  const filteredBrands = allBrands.filter(brand => {
    if (brandSearchVal && brand.name?.toLowerCase()?.includes(brandSearchVal.toLowerCase())) return true;
    else if (brandSearchVal && brand.domain?.toLowerCase()?.includes(brandSearchVal.toLowerCase())) return true;
    else if (brandSearchVal && brand.altDomains?.toLowerCase()?.includes(brandSearchVal.toLowerCase())) return true;
    else if (brandSearchVal && brand.altBrands?.toLowerCase()?.includes(brandSearchVal.toLowerCase())) return true;
    else if (brandSearchVal) return false;

    if (selectedAccountManagerId && selectedAccountManagerId !== 'unassigned' && selectedAccountManagerId !== brand.AccountManager_id) return false;
    if (selectedAccountManagerId && selectedAccountManagerId === 'unassigned' && brand.AccountManager_id) return false;
    if (inTaggingMode && !objectMatchesActiveTags(brand, ui)) return false;
    return true;
  });
  const themableBrands = _.filter(filteredBrands, brand => brand.subscription?.status === 'active');
  const orderedBrands = _.orderBy(filteredBrands, sortType, sortOrder);

  useEffect(() => {
    getBrands();
    getBrandRegistrationCodes();
  }, [getBrands, getBrandRegistrationCodes]);

  const commitBrandUpdates = (brand, updates) => {
    updateBrand(brand, updates).then(
      resp => {
        cogoToast.success(`Updated!`);
        getBrands();
      },
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  const changeBrandVal = (brand, type, initialVal = '', additionalNotes) => {
    const newVal = window.prompt(`${additionalNotes ? `Warning: ${additionalNotes}. ` : ''}Update ${type} to:`, initialVal || '');
    if (!newVal) return;
    commitBrandUpdates(brand, { [type]: newVal === 'null' ? null : _.isNaN(parseFloat(newVal)) ? newVal : parseFloat(newVal) });
  };

  const toggleIntegrationStatus = brand => {
    const statuses = ['NONE', 'PENDING', 'COMPLETE'];
    const nextStatus = statuses[statuses.indexOf(brand.integrationStatus) + 1] || statuses[0];
    updateBrand(brand, { integrationStatus: nextStatus }).then(
      resp => getBrands(),
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  const toggleStripeStatus = brand => {
    cogoToast.warn(`You can no longer edit this status manually, it will reflect the status of the Stripe subscription so please fix there.`);
  };

  const updateFeatureOverrides = brand => {
    const overrideCrossRetailer = window.prompt(`Force Override for Cross Retailer? (yes/no)`, brand.settings.overrideCrossRetailer ? 'yes' : 'no');
    if (!overrideCrossRetailer) return;

    const overrideListBuilding = window.prompt(`Force Override for List Building? (yes/no)`, brand.settings.overrideListBuilding ? 'yes' : 'no');
    if (!overrideListBuilding) return;

    updateBrandSettings(brand.settings, {
      overrideCrossRetailer: overrideCrossRetailer === 'yes',
      overrideListBuilding: overrideListBuilding === 'yes'
    }).then(
      resp => getBrands(),
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  const updateOfferings = brand => {
    const offersGiftingResp = window.prompt(`Offers Gifting? (yes/no)`, brand.settings.offersGifting ? 'yes' : 'no');
    if (!offersGiftingResp) return;
    const offersCodesResp = window.prompt(`Offers Codes? (yes/no)`, brand.settings.offersCodes ? 'yes' : 'no');
    if (!offersCodesResp) return;
    const offersCollaborationsResp = window.prompt(`Offers Collaborations? (yes/no)`, brand.settings.offersCollaborations ? 'yes' : 'no');
    if (!offersCollaborationsResp) return;
    const offersChatResp = window.prompt(`Offers Chat? (yes/no)`, brand.settings.offersChat ? 'yes' : 'no');
    if (!offersChatResp) return;

    updateBrandSettings(brand.settings, {
      offersGifting: offersGiftingResp === 'yes',
      offersCodes: offersCodesResp === 'yes',
      offersCollaborations: offersCollaborationsResp === 'yes',
      offersChat: offersChatResp === 'yes'
    }).then(
      resp => getBrands(),
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  const updateOutreachRestrictions = brand => {
    const outreachCountryCodesResp = window.prompt(
      `Allowed Outreach Country Codes? (Comma separated list of 2 letter ISO-3166-1 country codes)`,
      brand.settings.allowedOutreachCountryCodes || ''
    );

    const isClearing = outreachCountryCodesResp === '';
    const cleanOutreachCountryCodes = outreachCountryCodesResp.split(',').map(a => a.trim());

    if (!isClearing) {
      // validate
      const hasInvalidCountryCodes = cleanOutreachCountryCodes.some(code => !isValidCountryCode(code));

      if (hasInvalidCountryCodes) {
        return cogoToast.error('Invalid country codes, please check the list and try again.');
      }
    }

    const cleanCountryCodesStr = cleanOutreachCountryCodes.join(',');

    updateBrandSettings(brand.settings, {
      allowedOutreachCountryCodes: cleanCountryCodesStr || null
    }).then(
      resp => getBrands(),
      err => cogoToast.error('Had an error saving, check the network tab or ping engineering!')
    );
  };

  const toggleHealthCheckAlert = brand => {
    const turningOn = !!brand.settings.disableHealthCheckAlert;
    const confirm = window.confirm(`Are you sure you want to ${turningOn ? 'enable' : 'disable'} alerting for this health check?`);
    if (!confirm) return;

    updateBrandSettings(brand.settings, {
      disableHealthCheckAlert: !brand.settings.disableHealthCheckAlert
    }).then(
      resp => getBrands(),
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  const changeNetworkFee = (brand, isGMVCut = true) => {
    const waiveFee = window.prompt(`Waive Network Fee? (yes/no)`, brand.waive_network_rate ? 'yes' : 'no');
    if (waiveFee === 'yes') commitBrandUpdates(brand, { waive_network_rate: true });
    if (waiveFee === 'no') {
      if (isGMVCut) {
        const rateResp = window.prompt(
          `What is the new network rate as a proportion of GMV? Typically it is 2.9%.`,
          brand.network_rate_gmv_cut || ''
        );
        const rateFloat = parseFloat(rateResp);
        if (!rateFloat) return cogoToast.error('Invalid Option');
        commitBrandUpdates(brand, { waive_network_rate: false, network_rate_gmv_cut: rateFloat, network_rate: 0 });
      } else {
        const rateResp = window.prompt(`What is the new network rate as a proportion of commissions? Typically it is 15%.`, brand.network_rate || '');
        const rateFloat = parseFloat(rateResp);
        if (!rateFloat) return cogoToast.error('Invalid Option');
        commitBrandUpdates(brand, { waive_network_rate: false, network_rate: rateFloat, network_rate_gmv_cut: 0 });
      }
    }
  };

  const changeCodeVal = (code, type, initialVal = '', additionalNotes) => {
    const newVal = window.prompt(`${additionalNotes ? `Warning: ${additionalNotes}. ` : ''}Update ${type} to:`, initialVal);
    if (!newVal) return;
    updateRegistrationCode(code, { [type]: _.isNaN(parseFloat(newVal)) ? newVal : parseFloat(newVal) }).then(
      resp => {
        cogoToast.success(`Updated!`);
        getBrandRegistrationCodes();
      },
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  const sendBudgetInvoice = brand => {
    if (!brand.stripeCustomerId) return cogoToast.error(`Cannot send a budget invoice to a non-stripe customer, please set them up on Stripe.`);

    const amountToAddResp = window.prompt(`How much would you like to add to their budget? (Not including the SMS Fee)`);
    const amountToAdd = parseFloat(amountToAddResp);
    if (_.isNaN(amountToAdd)) return;

    const rateChargedResp = window.prompt(`What rate are we charging them for this?`, 14);
    const rateCharged = parseFloat(rateChargedResp);
    if (_.isNil(rateCharged)) return;

    const smsFee = parseFloat(((rateCharged * amountToAdd) / 100).toFixed(2));

    const confirm = window.prompt(
      `We will send a Stripe Invoice for $${amountToAdd + smsFee} to ${
        brand.name
      }. To send the invoice, type "yes". If you already sent the invoice, put the Stripe invoice ID here instead.`,
      'yes'
    );
    if (!confirm) return;

    const needsToCreateStripeInvoice = confirm === 'yes';
    let isStripeInvoicePaid = false;
    if (!needsToCreateStripeInvoice) {
      const isStripeInvoicePaidResp = window.prompt('Was this already paid? yes/no', 'no');
      if (!['yes', 'no'].includes(isStripeInvoicePaidResp)) return cogoToast.error('Invalid Option');
      isStripeInvoicePaid = isStripeInvoicePaidResp === 'yes';
    }
    cogoToast.info(`Generating Invoice...`);
    sendBudgetInvoiceAPI({
      Brand_id: brand.id,
      brand_amount: parseFloat(amountToAdd),
      sms_amount: (parseFloat(amountToAdd) * parseFloat(rateCharged)) / 100,
      stripeInvoiceId: confirm !== 'yes' ? confirm : null,
      isStripeInvoicePaid
    }).then(
      resp => {
        cogoToast.success(
          `Successfully sent invoice, see it here: https://dashboard.stripe.com${window.__IS_PROD__ ? '' : '/test'}/invoices/${
            resp.invoice?.stripeInvoiceId
          }`,
          {
            hideAfter: 8
          }
        );
        getBrands(); // Resync Data
      },
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  const archiveBrand = brand => {
    const agree = window.confirm(`Are you sure you want to archive ${brand.name}? This is not permanent and can be reverted by a developer.`);
    if (!agree) return;
    updateBrand(brand, { isArchived: true }).then(getBrands);
  };

  const deleteCode = async code => {
    const accept = window.confirm(`Are you sure you want to delete the code ${code.code}?`);
    if (!accept) return;
    await deleteRegistrationCode(code);
    getBrandRegistrationCodes();
    cogoToast.success(`Deleted code ${code.code}!`);
  };

  const updateBrandTemplate = brand => {
    const type = window.prompt(`Which template? Send Gifting = 1, Gifting Followup = 2, Send Code = 3, Code Followup = 4, General Outreach = 5`, 1);
    if (!type) return;
    const details = [
      {
        header: 'Update Template',
        subheader: 'Update Template description',
        key: 'sendGiftingTemplate',
        value: brand.settings['sendGiftingTemplate']
      },
      {
        header: 'Update Template',
        subheader: 'Update Template description',
        key: 'sendGiftingFollowupTemplate',
        value: brand.settings['sendGiftingFollowupTemplate']
      },
      {
        header: 'Update Template',
        subheader: 'Update Template description',
        key: 'sendCodeTemplate',
        value: brand.settings['sendCodeTemplate']
      },
      {
        header: 'Update Outreach Template',
        subheader: 'Update Template description',
        key: 'sendCodeFollowupTemplate',
        value: brand.settings['sendCodeFollowupTemplate']
      },
      {
        header: 'Update Template',
        subheader: 'Update Template description',
        key: 'sendOutreachTemplate',
        value: brand.settings['sendOutreachTemplate']
      }
    ][parseFloat(type) - 1];
    if (!details) return cogoToast.warn('Please enter a valid value');
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header={details.header}
          subheader={`Use {{FIRSTNAME}} to input the user first name {{NAME}} for full name and {{BRANDNAME}} for the brand name.`}
          preloaded={details.value}
          placeholder='Enter Template'
          onCancel={onClose}
          submitBtnDisplay='Save'
          rows={12}
          onSubmit={template => {
            updateBrandSettings(brand.settings, {
              [details.key]: template
            })
              .then(
                resp => {
                  getBrands();
                  cogoToast.success(`Successfully Updated Template`);
                },
                err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
              )
              .finally(() => {
                onClose();
              });
          }}
        />
      )
    });
  };

  const showRegistrationCodes = !selectedAccountManagerId;
  const accountManagersBrandsMap = _.groupBy(allBrands, 'AccountManager_id');
  return (
    <div className={cn('brands-outer-container', { tagging: inTaggingMode })}>
      <TagSidebar />
      {addingNewCode && (
        <BrandsNewRegistrationCode
          closeModal={() => setAddingNewCode(false)}
          addRegistrationCode={addRegistrationCode}
          getBrandRegistrationCodes={getBrandRegistrationCodes}
        />
      )}
      <div className='brands-inner-container'>
        <div className='section-header first'>
          <div>Brand Partners</div>
          <div className='action-btns'>
            <Select
              className='select'
              placeholder='Manager ID'
              defaultValue={{ label: 'All Brands', value: null }}
              onChange={({ value }) => setSelectedAccountManagerId(value)}
              options={[
                {
                  label: 'All Brands',
                  value: null
                },
                ..._.orderBy(_.keys(accountManagersBrandsMap), id => (id ? accountManagersBrandsMap[id].length : -1), 'desc').map(id => {
                  const brands = accountManagersBrandsMap[id];
                  const brand = brands[0];
                  const name = id === 'null' ? 'Unassigned' : brand.account_manager?.name || `User ${id}`;
                  return {
                    label: `${name} (${brands.length})`,
                    value: brand.AccountManager_id || 'unassigned'
                  };
                })
              ]}
            />

            <input className='brand-search' placeholder='Search Brands' onChange={e => setBrandSearchVal(e.target.value)} />
          </div>
        </div>

        <div className='brands-table-container'>
          <div className='brands-table'>
            <div className='brand row header'>
              {inTaggingMode ? (
                <>
                  <div onClick={() => props.setBrandsBeingTagged(orderedBrands)} className='cell small'>
                    Select
                  </div>
                  <div title='Created On' className='cell md'>
                    Created On
                  </div>
                  <div title='ID' className='cell small'>
                    ID
                  </div>
                  <div title='Name' className='cell lg'>
                    Name
                  </div>
                  <div title='Name' className='cell xl'>
                    Tags
                  </div>
                </>
              ) : (
                <>
                  <div title='Name' className='cell lg'>
                    Name
                  </div>
                  <div title='Created On' className='cell sm'>
                    Created On
                  </div>
                  <div title='ID' className='cell sm'>
                    ID
                  </div>
                  <div title='Email' className='cell sm'>
                    Email
                  </div>
                  <div title='Username' className='cell sm'>
                    Username
                  </div>
                  <div title='Registration Code' className='cell sm'>
                    Registration Code
                  </div>
                  <div title='Logo' className='cell sm'>
                    Logo
                  </div>
                  <div title='Bio' className='cell sm'>
                    Bio
                  </div>
                  <div title='Mobile Cover Image' className='cell sm'>
                    Cover
                  </div>
                  <div title='Mobile Badge' className='cell sm'>
                    Badge
                  </div>
                  <div title='Templates' className='cell sm'>
                    Templates
                  </div>
                  <div title='1: SMS Comm' className='cell sm'>
                    1: SMS Comm
                  </div>
                  <div title='1c: Pro Comm' className='cell sm'>
                    1c: Returning Comm
                  </div>
                  <div title='2: Alt Domains' className='cell md'>
                    2: Alt Domains
                  </div>
                  <div title='3: Merchant' className='cell sm'>
                    3: Merchant
                  </div>
                  <div title='4: Integration' className='cell sm'>
                    4: Integration
                  </div>
                  <div title='5: Scraped' className='cell sm'>
                    5: Scraped
                  </div>
                  <div title='6: Similar Brands' className='cell md'>
                    6: Similar Brands
                  </div>
                  <div title='7: Has Recs' className='cell sm'>
                    7: Has Recs
                  </div>
                  <div title='8: Live' className='cell sm'>
                    8: Live
                  </div>
                  <div title='MRR' className='cell sm'>
                    MRR
                  </div>
                  <div title='Stripe' className='cell sm'>
                    Stripe
                  </div>
                  <div title='PayPal' className='cell sm'>
                    PayPal
                  </div>
                  <div title='Budget' className='cell sm'>
                    Budget
                  </div>
                  <div title='Samples Remaining' className='cell sm'>
                    Samples Remaining
                  </div>
                  <div title='Restrictions' className='cell sm'>
                    Restrictions
                  </div>
                  <div title='Outreach Restrictions' className='cell sm'>
                    Outreach Restrictions
                  </div>
                  <div title='Overrides' className='cell sm'>
                    Overrides
                  </div>
                  <div onClick={() => clickSortType('commissionsThisWeek')} title='Comms last 7' className='cell sm'>
                    Comms Last 7
                  </div>
                  <div onClick={() => clickSortType('monthlyVolume')} title='# Comms' className='cell sortable sm'>
                    Monthly Volume
                  </div>
                  <div onClick={() => clickSortType('lastPing')} title='Last Ping' className='cell  sortable sm'>
                    Last Ping
                  </div>
                  <div title='Network Rate (Old)' className='cell sm'>
                    Network Rate (Old)
                  </div>
                  <div title='Network Rate (GMV)' className='cell sm'>
                    Network Rate (GMV)
                  </div>
                  <div title='Archive' className='cell sm'>
                    Report
                  </div>
                  <div title='Archive' className='cell sm'>
                    Archive
                  </div>
                </>
              )}
            </div>

            {_.map(orderedBrands, (brand, idx) => {
              const {
                id,
                name,
                commission_rate,
                commission_rate_returning,
                merchant,
                hasRecs,
                budget,
                logo,
                subscription,
                description,
                mobileCoverImage,
                mobileBadgeImage,
                tags,
                email,
                settings,
                paypalSubscriptionStatus,
                integrationStatus,
                samplesRequestsRemaining,
                productCount,
                network_rate,
                similarBrands,
                altDomains,
                lastPing,
                commissionsThisWeek,
                monthlyVolume,
                createdAt
              } = brand;

              const {
                offersGifting,
                offersCodes,
                offersCollaborations,
                offersChat,
                disableHealthCheckAlert,
                sendOutreachTemplate,
                sendCodeFollowupTemplate,
                sendCodeTemplate,
                sendGiftingFollowupTemplate,
                sendGiftingTemplate,
                allowedOutreachCountryCodes
              } = settings || {};
              const { mrr } = subscription || {};
              const hasScraper = brand.scraper?.id || brand.integrations?.length;
              const isComplete = merchant && hasScraper && hasRecs;
              const isShopMyIntegration = merchant?.source === 'shopmyshelf';
              const noIntegrationNeeded = integrationStatus === 'NONE' && merchant && !isShopMyIntegration;
              const needsIntegration = integrationStatus !== 'COMPLETE' && merchant && isShopMyIntegration;
              const visibleTags = _.filter(tags, tag => !isTagTypeHidden(tag.type, ui));
              const restrictions = _.concat(
                offersGifting ? [] : ['No Gifting'],
                offersCodes ? [] : ['No Codes'],
                offersCollaborations ? [] : ['No Collabs'],
                offersChat ? [] : ['No Chat']
              ).join(',');
              const overrides = _.concat(
                settings.overrideCrossRetailer ? ['Cross-Retailer'] : [],
                settings.overrideListBuilding ? ['Lists'] : []
              ).join(',');
              const selectedTagIds = _.map(getActiveTags(ui), 'id');
              const isHealthAlertActive =
                !disableHealthCheckAlert && moment().diff(moment(lastPing), 'days') >= 2 && isComplete && merchant.source === 'shopmyshelf';
              const allTemplates = [
                sendOutreachTemplate,
                sendCodeFollowupTemplate,
                sendCodeTemplate,
                sendGiftingFollowupTemplate,
                sendGiftingTemplate
              ];
              const numTemplates = _.filter(allTemplates).length;
              const mrrTooltip = `${name} is subscribed for $${mrr?.toFixed(0)} per month.`;

              return (
                <div className={cn('users row', { odd: idx % 2 })} key={id}>
                  {inTaggingMode ? (
                    <>
                      <div onClick={() => props.toggleBrandBeingTagged(brand)} className='cell small'>
                        <div className={cn('checkbox', { checked: isBrandBeingTagged(brand, ui) })}></div>
                      </div>
                      <div className='cell md'>{createdAt ? moment(createdAt).format('MMM Do') : '-'}</div>
                      <div title='ID' className='cell small'>
                        {id}
                      </div>
                      <div title='Name' className='cell lg'>
                        {name}
                      </div>
                      <div className='cell xl tags'>
                        {_.orderBy(
                          visibleTags,
                          tag => {
                            if (selectedTagIds.includes(tag.id)) return 10;
                            if (tag.type === 'general') return 9;
                            if (tag.type === 'geo') return 8;
                            if (tag.type === 'catalog') return 7;
                            if (tag.type === 'social') return 6;
                            if (tag.type === 'activity') return 5;
                            if (tag.type === 'behavior') return 4;
                            return 0;
                          },
                          'desc'
                        ).map((tag, idx) => {
                          const isSelected = selectedTagIds.includes(tag.id);
                          return (
                            <div
                              onClick={() => props.deleteBrandTag(tag, brand)}
                              key={tag.value + idx}
                              className={cn('tag', tag.type, { selected: isSelected })}
                            >
                              {tag.type === 'brandpromoter' ? `Competitor: ` : ''}
                              {tag.value}
                              <FontAwesomeIcon icon={faTimes} />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        onClick={() => changeBrandVal(brand, 'name', name, 'Make sure this matches the name in the catalog!')}
                        className='cell lg cell-header'
                      >
                        {name}
                      </div>
                      <div className='cell sm'>{createdAt ? moment(createdAt).format('MMM Do') : '-'}</div>
                      <div className='cell sm'>{id}</div>
                      <div onClick={() => copyToClipboard(email, true)} className='cell sm btn'>
                        {email ? 'COPY' : '-'}
                      </div>
                      <div onClick={() => copyToClipboard(brand.username, true)} className='cell sm btn'>
                        COPY
                      </div>
                      <div onClick={() => copyToClipboard(brand.registrationCode, true)} className='cell sm'>
                        {brand.registrationCode}
                      </div>
                      <div
                        onClick={() => changeBrandVal(brand, 'logo', logo, 'Please use a wide PNG with transparent background!')}
                        className='cell sm btn'
                      >
                        {logo ? 'UPDATE' : '-'}
                      </div>
                      <div
                        onClick={() => changeBrandVal(brand, 'description', description, `This will show up on the brand discover page.`)}
                        className='cell sm btn'
                      >
                        {description ? 'UPDATE' : '-'}
                      </div>
                      <div
                        onClick={() => changeBrandVal(brand, 'mobileCoverImage', mobileCoverImage, `This will show up on the app partner overlay.`)}
                        className={cn('cell sm btn', { incomplete: !mobileCoverImage })}
                      >
                        {mobileCoverImage ? 'UPDATE' : '-'}
                      </div>
                      <div
                        onClick={() =>
                          changeBrandVal(
                            brand,
                            'mobileBadgeImage',
                            mobileBadgeImage,
                            `This will show up on the app preview cells on the Latest and Partners tabs.`
                          )
                        }
                        className={cn('cell sm btn', { incomplete: !mobileBadgeImage })}
                      >
                        {mobileBadgeImage ? 'UPDATE' : '-'}
                      </div>
                      <div
                        onClick={() => updateBrandTemplate(brand)}
                        className={cn('cell sm btn', { incomplete: !numTemplates && isSubscribedToFeature(brand, 'CUSTOM_CODES') })}
                      >
                        {numTemplates ? numTemplates : isSubscribedToFeature(brand, 'CUSTOM_CODES') ? 'x' : '-'}
                      </div>
                      <div onClick={() => changeBrandVal(brand, 'commission_rate', commission_rate || 0)} className='cell sm'>
                        {!_.isNil(commission_rate) ? `${commission_rate}%` : '-'}
                      </div>
                      <div
                        onClick={() =>
                          changeBrandVal(
                            brand,
                            'commission_rate_returning',
                            commission_rate_returning || 0,
                            'You must make sure that the brand has the read_customers scope enabled, we highly recommend making this update from the Account Settings UI which will make this clear'
                          )
                        }
                        className='cell sm'
                      >
                        {commission_rate_returning ? `${commission_rate_returning}%` : '-'}
                      </div>
                      <div
                        onClick={() => changeBrandVal(brand, 'altDomains', altDomains || '', 'Use commas to separate the domains.')}
                        className='cell md'
                      >
                        {altDomains
                          ? altDomains
                              .split(',')
                              .map(a => a.trim())
                              .join(', ')
                          : '-'}
                      </div>
                      <div
                        onClick={() => copyToClipboard(`node update_merchants -o shopmyshelf`, `Run "node update_merchants -o shopmyshelf"`)}
                        className={cn('cell sm btn', { complete: merchant, incomplete: !merchant })}
                      >
                        {merchant ? merchant.source : 'TO DO'}
                      </div>
                      <div
                        onClick={() => toggleIntegrationStatus(brand)}
                        className={cn('cell sm btn', {
                          complete: integrationStatus === 'COMPLETE',
                          incomplete: needsIntegration
                        })}
                      >
                        {noIntegrationNeeded ? '-' : integrationStatus === 'COMPLETE' ? '✓' : integrationStatus}
                      </div>
                      <div
                        onClick={() => alert(`Ask a developer to scrape this site.`)}
                        className={cn('cell sm btn', { complete: merchant, incomplete: !hasScraper })}
                      >
                        {hasScraper ? '✓' : 'TO DO'}
                      </div>
                      <div
                        onClick={() => changeBrandVal(brand, 'similarBrands', similarBrands || '', 'Use commas to separate the brands.')}
                        className='cell md'
                      >
                        {similarBrands
                          ? similarBrands
                              .split(',')
                              .map(a => a.trim())
                              .join(', ')
                          : '-'}
                      </div>
                      <div
                        onClick={() =>
                          !similarBrands
                            ? cogoToast.warn(`First please add some similar brands to increase accuracty of recommendations.`)
                            : productCount
                            ? copyToClipboard(`node generate_recommendations -brandid ${id}`, `Run "node generate_recommendations -brandid ${id}"`)
                            : cogoToast.warn(`First need to create some products with the brand ${name} before generating recommendations.`)
                        }
                        className={cn('cell sm btn', { complete: hasRecs, incomplete: !hasRecs })}
                      >
                        {hasRecs ? '✓' : 'TO DO'}
                      </div>
                      <div className={cn('cell sm', { complete: merchant, incomplete: !isComplete })}>{isComplete ? '✓' : 'x'}</div>
                      <div className='cell sm'>
                        <Tooltip message={mrrTooltip} getIconDiv={() => <>{mrr ? `$${mrr.toFixed(0)}` : '-'}</>} />
                      </div>
                      <div onClick={() => toggleStripeStatus(brand)} className='cell sm'>
                        {brand.subscription.status?.toUpperCase() || '-'}
                      </div>
                      <div className='cell sm'>{paypalSubscriptionStatus || '-'}</div>
                      <div onClick={() => sendBudgetInvoice(brand)} className='cell sm'>
                        {budget?.id ? `$${budget.amountRemaining.toFixed(0)}` : '-'}
                      </div>
                      <div
                        onClick={() =>
                          changeBrandVal(
                            brand,
                            'samplesRequestsRemaining',
                            samplesRequestsRemaining || '',
                            'The gifting requests remaining are calculated on a monthly basis, with the brand having 50 per month on standard gifting. Only use this field if you want to add ADDITIONAL requests on top of their standard amount.'
                          )
                        }
                        className='cell sm'
                      >
                        {samplesRequestsRemaining || '-'}
                      </div>
                      <div className='cell sm' onClick={() => updateOfferings(brand)}>
                        <Tooltip message={restrictions}>{restrictions || '-'}</Tooltip>
                      </div>
                      <div className='cell sm' onClick={() => updateOutreachRestrictions(brand)}>
                        {allowedOutreachCountryCodes || '-'}
                      </div>
                      <div className='cell sm' onClick={() => updateFeatureOverrides(brand)}>
                        {overrides || '-'}
                      </div>
                      <div className='cell sm'>{commissionsThisWeek || '-'}</div>
                      <div className='cell sm'>{monthlyVolume ? `$${getPrettyNumber(monthlyVolume)}` : '-'}</div>
                      <div
                        onClick={() => toggleHealthCheckAlert(brand)}
                        className={cn('cell sm', { warning: isHealthAlertActive, disabledCheck: disableHealthCheckAlert })}
                      >
                        {lastPing || merchant?.source !== 'shopmyshelf' ? moment(lastPing || Date.now()).format('MMM Do') : '-'}
                      </div>
                      <div onClick={() => changeNetworkFee(brand, false)} className='cell sm'>
                        {brand.waive_network_rate ? 'WAIVED' : network_rate ? `${network_rate}%` : '-'}
                      </div>
                      <div onClick={() => changeNetworkFee(brand)} className='cell sm'>
                        {brand.waive_network_rate ? 'WAIVED' : brand.network_rate_gmv_cut ? `${brand.network_rate_gmv_cut}%` : '-'}
                      </div>
                      <Link to={`/brandreports?Brand_id=${id}`} className='cell sm btn'>
                        <FontAwesomeIcon icon={faExternalLink} />
                      </Link>
                      <div onClick={() => archiveBrand(brand)} className='cell sm btn'>
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className='section-header'>
          <div>Brand Theming</div>
        </div>
        <div className='brand row header'>
          <div className='cell md'>Logo</div>
          <div className='cell lg'>Name</div>
          <div className='cell md'>Primary Color</div>
          <div className='cell'>Promotion Images</div>
        </div>

        {_.map(themableBrands, (brand, idx) => {
          const { id, name, logo, promotionPageImages, primaryColor } = brand;
          const isTheming = themingBrand === brand.id;
          const themeBrand = () => setThemingBrand(id);
          return (
            <div className={cn('users row', { odd: idx % 2 })} key={id}>
              {!isTheming ? (
                <>
                  <div onClick={themeBrand} className='cell lg'>
                    {name} (Click to Theme)
                  </div>
                </>
              ) : (
                <>
                  <div onClick={() => changeBrandVal(brand, 'logo', logo, 'Please use a wide PNG with transparent background!')} className='cell md'>
                    <img src={logo} alt={name} />
                  </div>
                  <div className='cell lg'>{name}</div>
                  <div
                    style={{ backgroundColor: primaryColor || '#333', color: 'white', marginLeft: 12 }}
                    onClick={() => changeBrandVal(brand, 'primaryColor', primaryColor, 'Please use HEX format ex: #8ab78c')}
                    className='cell md'
                  >
                    {primaryColor || 'SET COLOR'}
                  </div>
                  <div
                    onClick={() =>
                      changeBrandVal(
                        brand,
                        'promotionPageImages',
                        promotionPageImages,
                        'Please use either 1, 2, 4, 8 or 16 images separated by a comma'
                      )
                    }
                    className='cell'
                  >
                    {promotionPageImages
                      ? promotionPageImages.split(',').map((img, idx) => <img className='promotion-img' key={idx} src={img} alt={img} />)
                      : 'SET IMAGES'}
                  </div>
                  <a target='_blank' rel='noopener noreferrer' href={`${getRootSMSUrl()}/promote/${brand.username}`} className='cell lg'>
                    Promotion Link
                  </a>
                </>
              )}
            </div>
          );
        })}

        {showRegistrationCodes && (
          <>
            <div className='section-header'>
              <div>Registration Codes</div>
              <div onClick={() => setAddingNewCode(true)} className='action-btn'>
                New Code
              </div>
            </div>
            <div className='brand row header'>
              <div className='cell md'>ID</div>
              <div className='cell lg'>Code</div>
              <div className='cell lg'>Brand Name</div>
              <div className='cell md'>Brand Logo</div>
              <div className='cell lg'>Brand Website</div>
              <div className='cell sm'>limit</div>
              <div className='cell sm'>Uses</div>
              <div className='cell sm'>Delete</div>
            </div>
            {_.map(codes.slice(0, showingAllRegistrationCodes ? 1e6 : 25), (code, idx) => {
              return (
                <div className={cn('users row', { odd: idx % 2 })} key={code.code}>
                  <div className='cell md'>{code.id}</div>
                  <div className='cell lg' onClick={() => changeCodeVal(code, 'code', code.code)}>
                    {code.code}
                  </div>
                  <div className='cell lg' onClick={() => changeCodeVal(code, 'brandName', code.brandName)}>
                    {code.brandName}
                  </div>
                  <div className='cell md' onClick={() => changeCodeVal(code, 'brandLogo', code.brandLogo)}>
                    <img src={code.brandLogo} alt={code.brandName} />
                  </div>
                  <div className='cell lg' onClick={() => changeCodeVal(code, 'brandWebsite', code.brandWebsite)}>
                    {code.brandWebsite}
                  </div>
                  <div className='cell sm'>{code.useLimit}</div>
                  <div className='cell sm'>{code.uses}</div>
                  <div onClick={() => deleteCode(code)} className='cell sm btn'>
                    x
                  </div>
                </div>
              );
            })}
            <div
              onClick={() => {
                setShowingAllRegistrationCodes(!showingAllRegistrationCodes);
              }}
            >
              {showingAllRegistrationCodes ? 'Hide' : 'Show All'}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Brands.propTypes = {
  brands: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  deleteBrandTag: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  getBrandRegistrationCodes: PropTypes.func.isRequired,
  toggleBrandBeingTagged: PropTypes.func.isRequired,
  setBrandsBeingTagged: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { brands, ui } = state;
  return { brands, ui };
};

export default connect(mapStateToProps, {
  deleteBrandTag,
  getBrands,
  getBrandRegistrationCodes,
  toggleBrandBeingTagged,
  setBrandsBeingTagged
})(Brands);
