import _ from 'lodash';
import commaNumber from 'comma-number';
import moment from 'moment';

export const getDomain = unsanitized_url => {
  let domain = _.first(_.last(_.split(unsanitized_url, '://')).split('/'))
    .split('www.')
    .join('');

  // Handle edge cases, more specific substring checks per domain first to avoid conflicts
  if (unsanitized_url.includes('allbeauty.com/gb')) domain += '/gb';
  else if (unsanitized_url.includes('allbeauty.com/us')) domain += '/us';
  else if (unsanitized_url.includes('beautypie.com/us')) domain += '/us';
  else if (unsanitized_url.includes('rodial.com/us')) domain += '/us';
  else if (unsanitized_url.includes('spacenk.com/us')) domain += '/us';
  else if (unsanitized_url.includes('spacenk.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('spacenk.com/fr')) domain += '/fr';
  else if (unsanitized_url.includes('spacenk.com/global')) domain += '/global';
  else if (unsanitized_url.includes('spacenk.com/ie')) domain += '/ie';
  else if (unsanitized_url.includes('charlottetilbury.com/ie')) domain += '/ie';
  else if (unsanitized_url.includes('charlottetilbury.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('charlottetilbury.com/fr')) domain += '/fr';
  else if (unsanitized_url.includes('charlottetilbury.com/eu')) domain += '/eu';
  else if (unsanitized_url.includes('charlottetilbury.com/de')) domain += '/de';
  else if (unsanitized_url.includes('charlottetilbury.com/nl')) domain += '/nl';
  else if (unsanitized_url.includes('charlottetilbury.com/it')) domain += '/it';
  else if (unsanitized_url.includes('charlottetilbury.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('charlottetilbury.com/us')) domain += '/us';
  else if (unsanitized_url.includes('charlottetilbury.com/au')) domain += '/au';
  else if (unsanitized_url.includes('charlottetilbury.com/es')) domain += '/es';
  else if (unsanitized_url.includes('theordinary.com/en-nz')) domain += '/en-nz';
  else if (unsanitized_url.includes('deciem.com/en-nz')) domain += '/en-nz';
  else if (unsanitized_url.includes('theordinary.com/en-au')) domain += '/en-au';
  else if (unsanitized_url.includes('theordinary.com/en-us')) domain += '/en-us';
  else if (unsanitized_url.includes('theordinary.com/en-ca')) domain += '/en-ca';
  else if (unsanitized_url.includes('theordinary.com/en-mx')) domain += '/en-mx';
  else if (unsanitized_url.includes('niod.com/en-au')) domain += '/en-au';
  else if (unsanitized_url.includes('niod.com/en-nz')) domain += '/en-nz';
  else if (unsanitized_url.includes('niod.com/en-us')) domain += '/en-us';
  else if (unsanitized_url.includes('niod.com/en-ca')) domain += '/en-ca';
  else if (unsanitized_url.includes('niod.com/en-mx')) domain += '/en-mx';
  else if (unsanitized_url.includes('deciem.com/en-au')) domain += '/en-au';
  else if (unsanitized_url.includes('libertylondon.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('net-a-porter.com/en-au')) domain += '/en-au';
  else if (unsanitized_url.includes('cos.com/en_gbp')) domain += '/en_gbp';
  else if (unsanitized_url.includes('cos.com/en_eur')) domain += '/en_eur';
  else if (unsanitized_url.includes('cos.com/en-eu-e')) domain += '/en-eu-e';
  else if (unsanitized_url.includes('cos.com/en-eu')) domain += '/en-eu';
  else if (unsanitized_url.includes('cos.com/pl-pl')) domain += '/pl-pl';
  else if (unsanitized_url.includes('cos.com/es-es')) domain += '/es-es';
  else if (unsanitized_url.includes('cos.com/it-it')) domain += '/it-it';
  else if (unsanitized_url.includes('cos.com/fr-fr')) domain += '/fr-fr';
  else if (unsanitized_url.includes('fresh.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('harveynichols.com/int')) domain += '/int';
  else if (unsanitized_url.includes('italist.com/us')) domain += '/us';
  else if (unsanitized_url.includes('ae.com/us/en/c/aerie')) domain += '/us/en/c/aerie';
  else if (unsanitized_url.includes('ae.com/us/en/p/aerie')) domain += '/us/en/p/aerie';
  else if (unsanitized_url.includes('ae.com/us/en/p/ae/aerie')) domain += '/us/en/p/ae/aerie';
  else if (unsanitized_url.includes('ae.com/us/en/c/ae/aerie')) domain += '/us/en/c/ae/aerie';
  else if (unsanitized_url.includes('ugg.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('stradivarius.com/gb')) domain += '/gb';
  else if (unsanitized_url.includes('stradivarius.com/us')) domain += '/us';
  else if (unsanitized_url.includes('harrods.com/en-us')) domain += '/en-us';
  else if (unsanitized_url.includes('mrporter.com/en-au')) domain += '/en-au';
  else if (unsanitized_url.includes('mrporter.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('mrporter.com/en-de')) domain += '/en-de';
  else if (unsanitized_url.includes('shop.mango.com/us')) domain += '/us';
  else if (unsanitized_url.includes('shop.mango.com/au')) domain += '/au';
  else if (unsanitized_url.includes('shop.mango.com/mx')) domain += '/mx';
  else if (unsanitized_url.includes('shop.mango.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('calzedonia.com/us')) domain += '/us';
  else if (unsanitized_url.includes('sephora.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('dior.com/en_us/beauty')) domain += '/en_us/beauty';
  else if (unsanitized_url.includes('zadig-et-voltaire.com/us/en')) domain += '/us/en';
  else if (unsanitized_url.includes('reiss.com/fr/fr')) domain += '/fr/fr';
  else if (unsanitized_url.includes('reiss.com/nl/en')) domain += '/nl/en';
  else if (unsanitized_url.includes('reiss.com/ie/en')) domain += '/ie/en';
  else if (unsanitized_url.includes('reiss.com/au/en')) domain += '/au/en';
  else if (unsanitized_url.includes('reiss.com/us/en')) domain += '/us/en';
  else if (unsanitized_url.includes('reiss.com/de/de')) domain += '/de/de';
  else if (unsanitized_url.includes('anthropologie.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('anthropologie.com/fr-fr')) domain += '/fr-fr';
  else if (unsanitized_url.includes('hudabeauty.com/us/en_US')) domain += '/us/en_US';
  else if (unsanitized_url.includes('hudabeauty.com/dt/en_ES')) domain += '/dt/en_ES';
  else if (unsanitized_url.includes('hudabeauty.com/dt/en_FR')) domain += '/dt/en_FR';
  else if (unsanitized_url.includes('hudabeauty.com/dt/en_IT')) domain += '/dt/en_IT';
  else if (unsanitized_url.includes('hudabeauty.com/dt/en_PL')) domain += '/dt/en_PL';
  else if (unsanitized_url.includes('hudabeauty.com/en_IN')) domain += '/en_IN';
  else if (unsanitized_url.includes('hudabeauty.com/en_AU')) domain += '/en_AU';
  else if (unsanitized_url.includes('hudabeauty.com/ar_SA')) domain += '/ar_SA';
  else if (unsanitized_url.includes('hudabeauty.com/en_CA')) domain += '/en_CA';
  else if (unsanitized_url.includes('hudabeauty.com/en_KW')) domain += '/en_KW';
  else if (unsanitized_url.includes('hudabeauty.com/en_AE')) domain += '/en_AE';
  else if (unsanitized_url.includes('hudabeauty.com/dt/en_DE')) domain += '/dt/en_DE';
  else if (unsanitized_url.includes('mytheresa.com/gb')) domain += '/gb';
  else if (unsanitized_url.includes('shop.lululemon.com/en-ca')) domain += '/en-ca';
  else if (unsanitized_url.includes('sezane.com/us')) domain += '/us';
  else if (unsanitized_url.includes('sezane.com/eu')) domain += '/eu';
  else if (unsanitized_url.includes('sezane.com/fr')) domain += '/fr';
  else if (unsanitized_url.includes('sezane.com/en')) domain += '/en';
  else if (unsanitized_url.includes('sezane.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('meandem.com/us')) domain += '/us';
  else if (unsanitized_url.includes('uniqlo.com/us')) domain += '/us';
  else if (unsanitized_url.includes('uniqlo.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('roddandgunn.com/us')) domain += '/us';
  else if (unsanitized_url.includes('roddandgunn.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('roddandgunn.com/au')) domain += '/au';
  else if (unsanitized_url.includes('roddandgunn.com/nz')) domain += '/nz';
  else if (unsanitized_url.includes('aldoshoes.com/us')) domain += '/us';
  else if (unsanitized_url.includes('aldoshoes.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('freepeople.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('toryburch.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('toryburch.com/de-de')) domain += '/de-de';
  else if (unsanitized_url.includes('armani.com/en-us')) domain += '/en-us';
  else if (unsanitized_url.includes('armani.com/en-nl')) domain += '/en-nl';
  else if (unsanitized_url.includes('armani.com/es-es')) domain += '/es-es';
  else if (unsanitized_url.includes('armani.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('armani.com/de-de')) domain += '/de-de';
  else if (unsanitized_url.includes('armani.com/fr-fr')) domain += '/fr-fr';
  else if (unsanitized_url.includes('armani.com/it-it')) domain += '/it-it';
  else if (unsanitized_url.includes('arket.com/en_gbp')) domain += '/en_gbp';
  else if (unsanitized_url.includes('ruggable.com/en-CA')) domain += '/en-CA';
  else if (unsanitized_url.includes('stories.com/en_usd')) domain += '/en_usd';
  else if (unsanitized_url.includes('nike.com/gb')) domain += '/gb';
  else if (unsanitized_url.includes('allsaints.com/us')) domain += '/us';
  else if (unsanitized_url.includes('allsaints.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('urbanoutfitters.com/fr-fr')) domain += '/fr-fr';
  else if (unsanitized_url.includes('urbanoutfitters.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('guess.com/ca')) domain += '/ca';

  return domain;
};

export const prettyNumber = number =>
  Math.abs(number) > 999 ? Math.sign(number) * (Math.abs(number) / 1000).toFixed(1) + 'k' : Math.sign(number) * Math.abs(number);

export const formatAmountInCurrency = (amount, currency) => new Intl.NumberFormat('EN', { style: 'currency', currency }).format(amount);

export const getPrettyNumber = (numberRaw, options = {}) => {
  /*  
    Shorten the number with standard endings.
    
    1000000 => 1m
    124000 => 124k
    12500 => 12.5k
    1149 => 1.1k
    839 => 839
  */
  const { useAbsolute } = options;
  let number = useAbsolute ? Math.abs(numberRaw) : numberRaw;
  if (number > 1e6) {
    let millions = Math.floor(number / 1e6);
    let hundredthousands = Math.round((number % 1e6) / 1e5);
    if (hundredthousands === 10) {
      millions += 1;
      hundredthousands = 0;
    }
    return millions >= 100 ? `${millions}m` : `${millions}${hundredthousands ? `.${hundredthousands}` : ''}m`;
  } else if (number > 1e3) {
    let thousands = Math.floor(number / 1e3);
    let hundreds = Math.round((number % 1e3) / 1e2);
    if (hundreds === 10) {
      thousands += 1;
      hundreds = 0;
    }
    return thousands >= 100 ? `${thousands}k` : `${thousands}${hundreds ? `.${hundreds}` : ''}k`;
  }
  return number.toFixed(0);
};

export const getPrettyPriceDisplay = (priceRaw, options = {}) => {
  /*
    1.5 => $1.50
    2 => $2
  */
  const { perDay, precision, useUSD, useAbsolute } = options;
  if (!priceRaw && priceRaw !== 0) return '';
  const price = parseFloat(useAbsolute ? Math.abs(priceRaw) : priceRaw);
  const prettyNumber = commaNumber(price.toFixed(!_.isNil(precision) ? precision : price === Math.floor(price) ? 0 : 2));
  return `${useUSD ? '' : '$'}${prettyNumber}${useUSD ? ' USD' : ''}${perDay ? ' per day' : ''}`;
};

export const getPercentileNumber = number => {
  number = Number.parseInt(number) || 0;
  const last_digit_of_number = number % 10;

  switch (last_digit_of_number) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

export const getServerFormattedDate = (dateString = '') => moment(dateString || new Date()).format('YYYY-MM-DD HH:mm:ss');
export const getGMTTime = () => new Date(new Date().toGMTString()).getTime();

export const stringToReadableDate = ({ day = '01', month = '01', year = '2000', date, week }) => {
  const full_date = date ? moment(date) : moment(`${year}-${month}-${day}`);
  week && full_date.week(week);

  const [month_string, day_string] = full_date.format('MMMM dddd YYYY').split(' ');
  const [shortened_month_string] = full_date.format('MMM DD YYYY').split(' ');

  return {
    month: month_string,
    shortened_month: shortened_month_string,
    day_number: full_date.day(),
    day_of_week: day_string,
    day_of_month: full_date.date(),
    year,
    dayOfWeek: full_date.weekday,
    weekOfYear: full_date.weekYear,
    numDaysInMonth: full_date.daysInMonth
  };
};
